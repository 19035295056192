@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  

body {
  font-family: 'Nunito', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    overflow-x: hidden;
}

@mixin responsive-font-size($percentage) {
    font-size: $percentage * 0.05vw;
  }

$primary-color: #2c3e50;
$c-primary-color: #34495e; /*#2c4854, #2c4c59, ##503e2c;*/
$secondary-color: #3498db;
$neutral-color: #ffffff;
$primary-transition: 500ms ease-in-out;
$default-font-size: 16px;

@mixin link-text {
  color: $neutral-color;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
}

@mixin responsive-font-size($percentage) {
  font-size: $percentage * 0.05vw;
}

@mixin responsive-element($percentage) {
  transform: scale($percentage * 0.05vw);
}

.copy-icon {
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.copy-icon:hover {
  transform: scale(1.1);
}

.copied {
  color: green; /* Change color when copied */
}

.copy-feedback {
  color: green;
  font-size: 0.9rem;
  margin-top: 10px;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.invalid {
  border: 1px solid red;
}

/* Error CSS */
// .error-message {
//   color: #e74c3c;
//   background-color: #fce4e4;
//   border: 1px solid #e74c3c;
//   padding: 10px;
//   border-radius: 4px;
//   text-align: center;
// }

// .retry-button {
//   background-color: #3498db;
//   color: white;
//   padding: 5px 10px;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   margin-top: 10px;
// }

// .retry-button:hover {
//   background-color: #2980b9;
// }
// /* Loader CSS */
// .loader-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100px; /* Adjust based on your layout */
// }

// .loader {
//   border: 4px solid rgba(0, 0, 0, 0.1);
//   border-left-color: #22a6b3;
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   animation: spin 1s linear infinite;
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

table {
  overflow: hidden;
}

.zebra-table {
  border-collapse: collapse;
  overflow-x: hidden;
}

.zebra-table tr:nth-child(even) {
  background-color: #274B85;
  color: white;
}

.zebra-table th,
.zebra-table td {
  border-top: 1px solid #000;
  padding: 8px;
}


.desc-box{
  // background-color: #274B85;
  height: calc(100%);
  text-wrap: wrap;
  margin-bottom: 3em;
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-down {
  animation: fade-in-down 0.5s ease-out;
}