:root {
    --blur: #274B85;
    --dark-blue: #274B85;
    /* --dark-blue: #020414; */
    --input-border: #0A0D1233;
}

* {
    padding: 0px;
    margin: 0px;
}

body,
html {
    font-size: 10px;
    box-sizing: border-box;
    font-family: "Nunito", sans-serif;
    color: #0A0D12;
}

a,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
}

button {
    cursor: pointer;
}

a {
    color: #0A0D12;
    text-decoration: none;
}

.body-text {
    font-size: 1.8rem;
    line-height: 1.5;
}

.line-text {
    font-size: 1.6rem;
    line-height: 1.5;
}

.tile-header {
    font-size: 2rem;
}

/* layout starts */
.mt_12 {
    margin-top: 12%;
}

.mt_20 {
    margin-top: 20%;
}

.mtp_20 {
    margin-top: 20px;
}

.mt_15 {
    margin-top: 15%;
}

.mt_5 {
    margin-top: 5%;
}

.mt_2 {
    margin-top: 2%;
}

.mt_10 {
    margin-top: 10%;
}

.mt_3 {
    margin-top: 3% !important;
}

.mt_4 {
    margin-top: 4% !important;
}

.mt_8 {
    margin-top: 8% !important;
}

.py_10 {
    padding-top: 10%;
    padding-bottom: 10%;
}

.grid-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.flex-div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    font-family: "Nunito", sans-serif;
    cursor: pointer;
}

.single-payment-link.active {
    background: var(--blur);
}

.single-payment-link.active {
    color: #fff;
    width: 100%;
}

.single-payment-link img {
    height: 1.6rem;
    width: 100%;
}

.single-payment-link img.big {
    height: 2.3rem;
    width: auto;
}

.ant-modal-close {
    display: none;
}

.modal-cancel {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0px;
    right: -60px;
    cursor: pointer;
}

/* layout ends */
/* button starts */
.btn {
    height: 64px;
    border-radius: 8px;
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
}

.btn-blue {
    background: var(--blur);
    border: 2px solid var(--blur);
    color: #fff;
}

.btn.btn-default {
    background: transparent;
    border: none;
    padding: 0px;
    height: unset;
}

.btn-blank {
    background: transparent;
    border: none;
    text-align: center;
}

/* button ends */
.payment-link-section {
    min-height: 100dvh;
    width: 100%;
    background: #efefef;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #fff;
}

.payment-link-section .business-inner {
    width: 50%;
    background: #fff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
    height: max-content;
    position: relative;
    padding-top: 8rem;
    text-align: center;
    border-radius: 8px;
}

.payment-link-section .business-inner .biz-logo-div {
    height: 10rem;
    background: #fff;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
    position: absolute;
    top: -4.5rem;
    left: 35%;
    right: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-link-section .business-inner .biz-logo-div h3 {
    font-size: 3rem;
}

.payment-link-section .business-inner p {
    font-size: 1.3rem;
    width: 90%;
    margin: 0px auto;
    display: block;
    padding-bottom: 12rem;
    line-height: 1.6;
}

.payment-link-section .business-inner h4 {
    font-size: 2.2rem;
    margin-bottom: 15px;
}

.payment-link-section .business-inner h5 {
    font-size: 1.5rem;
    font-weight: normal;
    width: 50%;
    margin: 0px auto;
    margin-bottom: 20px;
    display: block;
}

.payment-link-section .business {
    background: #F3F4F6;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.payment-form-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-link-div {
    height: 100%;
    width: 100%;
}

.business-inner .amount-to-pay {
    background: #BF125D;
    padding: 20px 0px;
    text-align: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
}

.business-inner .amount-to-pay h3 {
    color: #fff;
    font-size: 2.2rem;
}

span.currency {
    font-size: 1.4rem;
}

.payment-link-section .payment-box {
    width: 40%;
    padding-top: 15rem;
    padding-bottom: 10rem;
}

.payment-link-section .payment-div {
    background: #F8F8F8;
    width: 100%;
    border-radius: 16px;
}

.payment-link-section .payment-div .payment-div-body {
    padding: 28px;
}

.payment-link-section .payment-div .payment-div-body form {
    width: 100%;
}

.payment-link-section .payment-div input[type=email],
.payment-link-section .payment-div input[type=text] {
    background: transparent;
    border: 1px solid var(--input-border) !important;
    height: 6rem;
    width: calc(100% - 20px);
    width: 100%;
    border-radius: 8px;
    padding: 0px 10px;
    font-family: "Nunito", sans-serif;
}

.form-group {
    margin-bottom: 20px;
}

.payment-link-section .payment-div button.main {
    width: 100%;
    display: block;
    margin-top: 32px;
}

.payment-link-section .payment-div-header {
    border-bottom: 2px solid #0A0D121F;
    padding: 30px 20px;
    width: calc(100% - 40px);
    display: block;
}

.payment-link-section .payment-div-header h5 {
    font-size: 2rem;
}

.payment-link-section .payment-div-header button {
    display: flex;
    grid-gap: 10px;
    align-items: center;
}

.payment-link-section .foot-note p {
    font-size: 1.3rem;
    text-align: center;
}

.payment-link-section .customer-form-div {
    width: 65%;
}

.payment-modal-disp {
    display: grid;
    grid-template-columns: .7fr 2fr;
    /* grid-gap: 30px; */
}

.payment-modal-disp .payment-options-list {
    background: #F4F4F4;
    /* border-radius: 8px 0px 0px 8px; */
    width: 100%;
    height: 100%;
}

.payment-modal-disp .payment-option-list-header {
    height: 6rem;
    /* border-bottom: 3px solid #EBECEC; */
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.payment-modal-disp .payment-option-list-header h5 {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.ant-modal {
    width: 600px !important;
}

.ant-modal-content {
    padding: 0px !important;
}

.card-form-div {
    padding-right: 30px;
    padding: 40px 20px 40px 20px;
}

.payment-modal-disp .single-payment-link {
    line-height: 4.5;
    border-bottom: 2px solid #EBECEC;
    padding: 0px 15px;
    list-style: none;
}



.top-modal {
    /* border-bottom: 3px solid #EBECEC; */
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 5px);
    font-family: "Nunito", sans-serif;
    padding: 0px 10px;
}

.top-modal .grid-flex {
    width: 100%;
}

.top-modal img {
    height: 2.4rem;
}

.top-modal p {
    font-size: 1.2rem;
}

.top-modal p .currency {
    font-size: 1.2rem;
}

/* .payment_ */

.top-modal {}

.button-cover {
    margin-top: 2rem;
}

.payment-link-section .foot-note {
    margin-top: 15px;
}



.payment-link-section .payment-div-body-header {
    width: 50%;
    margin: 0px auto;
    display: block;
    text-align: center;
    margin-bottom: 25px;
}

.body-header-footer {
    margin: 0px auto;
    text-align: center;
    display: block;
    width: max-content;
}

.body-header-footer p {
    font-size: 1.5rem;
    margin-bottom: 0px;
}

.payment-link-section .payment-div-body-header h2 {
    font-size: 3rem;
    color: var(--blur);
    font-weight: 600;
    margin-bottom: 8px;
}

.payment-link-section .payment-div-body-header h2 span.money-kobo {
    color: #274B8580;
}

.payment-link-section .payment-div-body-header h4 {
    font-size: 1.6rem;
    margin-bottom: 8px;
}

.payment-link-section .payment-div-body-header .flex-div p {
    font-size: 1.6rem;
    margin-bottom: 0px;
}

.link-footnote {
    margin-top: 10px;
}

.link-footnote p {
    /* font-size: 2.4rem; */
    text-align: center;
    font-size: 1.6rem;
}

.link-footnote p span {
    font-weight: 600;
    color: var(--blur);
}


/* navigation */
.navigation ul {
    display: flex;
    grid-gap: 22px;
}

.navigation li {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.navigation li .current-page-marker .page-id {
    background: #40444B;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation li .current-page-marker .page-id.active {
    background: var(--dark-blue);
}

.navigation li .current-page-marker .page-id h5 {
    color: #EFEFEF;
    font-size: 1.1rem;
}

.navigation li h3 {
    font-size: 1.6rem;
}

.navigation .inner-div {
    display: flex;
    align-items: center;
    grid-gap: 40px;
}

.navigation .inner-div h2 {
    font-size: 1.8rem;
}

.nav-box {
    background: #F8F8F8;
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0px;
}

.navigation {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
}

.navigation .page-label {
    border-left: 2px solid #0A0D1233;
    padding-left: 30px;
}


/* payment-method */
.payment-method .single-payment-method {
    border: 1px solid var(--input-border);
    padding: 20px 30px;
    border-radius: 8px;
    display: block;
    cursor: pointer;
}

.payment-method .single-payment-method h5 {
    font-size: 1.8rem;
}

.payment-method .single-payment-method .btn {
    height: unset;
    font-size: 1.6rem;
    padding: 10px 15px;
    border-radius: 40px;
    text-align: center;
    width: max-content;
}

.payment-method .single-payment-method .btn.tag p {
    font-size: 1.3rem;
}

.payment-method .single-payment-method.active {
    border: 1px solid var(--dark-blue);
}

.payment-method .single-payment-method.recommended {
    padding: 15px 30px;
}

.payment-method .grid-6 input {
    text-align: center;
}

label {
    font-size: 1.3rem;
    margin-bottom: 5px;
    display: block;
    font-family: "Nunito", sans-serif;
}

.center {
    text-align: center;
    margin-bottom: 10px;
}

.center h3 {
    font-weight: 600;
}

.center p {
    font-size: 1.5rem;
    font-family: "Nunito", sans-serif;
}

.bank-detail-box {
    background: #F4F4F4;
    text-align: center;
    padding: 20px;
    border-radius: 12px;
}

.bank-detail-box h5 {
    /* font-size: 1.4rem; */
    margin-bottom: 8px;
}

.bank-detail-box h3 {
    /* font-size: 5rem; */
    margin-bottom: 0px;
    line-height: 1;
    letter-spacing: 1px;
}

.bank-detail-box h3.smaller {
    /* font-size: 2.5rem; */
}

.bank-detail-box p {
    /* font-size: 1rem; */
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.payment-sect-summary img.hourglass {
    width: 10%;
    height: auto;
}
@media(max-width: 720px) {
    .bank-detail-box h3 {
        font-size: 2.5rem;
    }
    .bank-detail-box h3.smaller {
        font-size: 2rem;
    }
}

.account-bar {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
    grid-gap: 20px;
}

.account-bar img {
    height: 2rem;
    width: auto;
}

.payment-sect-summary p {
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.5;
}

.login .payment-sect-summary p {
    width: 80%;
    margin: 0px auto;
    display: block;
}

.payment-sect-summary p b {
    font-weight: 600;
}

.payment-sect-summary h4 {
    font-size: 2rem;
    margin: 0px auto;
    display: block;
    text-align: center;
    margin-bottom: 5px;
}

.align-center {
    width: 80%;
    margin: 0px auto;
    display: block;
    text-align: center;
}

.payment-sect-summary img {
    text-align: center;
    margin: 0px auto;
    display: block;
    width: max-content;
    width: 30%;
}

.payment-sect-summary img.circle-checkmark {
    width: 20%;
}

.payment-div-body ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payment-div-body ul li p {
    font-size: 1.8rem;
}

.payment-div-body ul li p span {
    font-weight: 600;
}

input[type=checkbox] {
    height: 20px;
    width: 20px;
}

.otp-box input {
    width: 50% !important;
}

.customer-top-div h5 {
    margin-bottom: 60px;
    text-align: center;
    font-size: 1.8rem;
}

.customer-bottom-div a {
    font-size: 1.4rem;
}

input {
    height: 2rem !important;
    height: 5rem !important;
    border: 1px solid #40444B ;
    border: 1px solid #EBECEC ;
    background: #fff !important;
    border-radius: 6px !important;
    width: 100% !important;
}

.otp-box input {
    height: 5rem !important;
}

.otp-box div {
    grid-gap: 5px;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
}

.css-batk84-MuiInputBase-root-MuiFilledInput-root::before {
    border: none !important;
}

.grid-div-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.payment-div-body .flex-div a.btn-blank.flex-div {
    justify-content: center;
}


/* bank */
.bank-list {
    border: 2px solid #0A0D121A;
    border-radius: 12px;
    padding: 15px;
}

.bank-list .single-bank-data .bank-data-box .bank-box {
    background: #0A0D120D;
    height: 56px;
    width: 56px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bank-list .single-bank-data {
    border-bottom: 2px solid #0A0D121A;
    padding: 22px 0px;
}

.bank-list .single-bank-data p {
    font-size: 1.4rem;
    color: #0A0D1299;
    margin-bottom: 5px;
}

.bank-list .single-bank-data h4 {
    font-size: 1.8rem;
    color: #0A0D12;
}

.bank-list .single-bank-data img.huge {
    height: 2.4rem;
}

.bank-list .single-bank-data.grid-flex {
    align-items: center;
}

.bank-list .single-bank-data.grid-flex:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}

.bank-list .single-bank-data img.copy-icon {
    height: 2rem;
}


.payment-sect-summary img.loader {
    width: 60px;
    height: auto;
}


.payment-sect-summary h4.modal-header {
    font-size: 3rem;
}

.form-error {
    font-size: 1.3rem;
    color: #BF125D;
}

.hash
{
    width: 20px;
    height: 24px;
}

.loading-indicator {
    position: relative; /*Change to relative to position within the modal*/
    z-index: 10; /* Ensure it's above other content but not full screen */
    /* You can add padding or margins as needed */
  }
  
  .error-message {
    color: red; /* Change the color to indicate an error */
    padding: 10px; /* Add some padding */
    margin-top: 10px; /* Add spacing from above elements */
    /* Optionally, position it more specifically if needed */
  }
  
  /* Ensure your modal content does not get hidden by overflow */
  .payment-modal-showcase {
    overflow: visible; /* Allow content to show without being clipped */
  }
  
/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.desktop {
    display: block !important;
}

.mobile {
    display: none !important;
}


@media screen and (max-width: 720px) {
    .payment-link-section .payment-div {
        background: unset !important;
        border-radius: unset !important;
    }

    .payment-link-section .payment-div .payment-div-body {
        padding: 0px;
    }

    .payment-link-section {
        display: block;
    }

    .payment-link-section .business {
        display: none;
    }

    .payment-link-section .customer-form-div {
        width: calc(100% - 3%);
    }

    .customer-bottom-div .grid-flex {
        display: flex;
        grid-gap: 10px;
        flex-direction: column;
        width: max-content;
        margin: 0px auto;
        text-align: center;
    }

    .customer-top-div h5 {
        margin-bottom: 20px;
    }

    .payment-modal-disp {
        display: block;
    }

    .ant-modal {
        width: 100% !important;
        max-width: unset !important;
        height: 100%;
        top: 0px;
        margin: 0px !important;
        border-radius: 0px !important;
    }

    .payment-modal-disp .payment-options-list {
        display: none;
    }

    .ant-modal,
    .ant-modal-content {
        height: 100% !important;
    }

    .ant-modal-body {
        height: 100dvh;
    }

    .card-form-div {
        padding: 60px 10px;
    }

    .payment-method .single-payment-method h5 {
        font-size: 1.5rem;
    }

    /* .ant-modal div:first-child {
        height: 100% !important;
        width: 100%;
    } */
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }
    .external .card-form-div {
        padding-top: 0px;
    }
}